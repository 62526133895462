@import '../config';
@import '../mixins/typography';
@import '../mixins/scroll';

.container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: rgba(map-get($colors, copy), 0.9);
  display: flex;
  align-items: flex-end;
  z-index: 50;
  animation-name: fadeIn;
  animation-duration: 0.35s;

  @include breakpoint(large, min) {
    justify-content: center;
    align-items: center;
  }
}

@keyframes fadeIn {
  from {
    background-color: rgba(map-get($colors, copy), 0.2);
  }

  to {
    background-color: rgba(map-get($colors, copy), 0.9);
  }
}

.content {
  @include scrollY();
  position: relative;
  width: 100vw;
  background-color: map-get($colors, foreground);
  padding: map-get($map: $spacing, $key: sitePadding);
  border-top-left-radius: map-get($spacing, 'borderRadius');
  border-top-right-radius: map-get($spacing, 'borderRadius');
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-height: 80vh;

  &-button {
    margin-top: 1rem;
  }

  @include breakpoint(large, min) {
    width: 35rem;
    min-width: 35rem;
    max-width: 35rem;
    padding: 2.25rem 2rem;
    border-radius: map-get($spacing, 'borderRadius');
    width: calc(100% - map-get($spacing, sitePadding) * 2);
  }

  &--large {
    @include breakpoint(large, min) {
      width: 60rem;
      min-width: 60rem;
      max-width: 60rem;
    }
  }

  &--full-screen {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    padding: 0;
  }
}

.remove-bottom-padding {
  padding-right: map-get($map: $spacing, $key: sitePadding);
  padding-left: map-get($map: $spacing, $key: sitePadding);
  padding-top: map-get($map: $spacing, $key: sitePadding);
  padding-bottom: 0 !important;
  max-height: 80vh;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: auto;
}

.icon {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-bottom: 2rem;
  max-width: 10rem;
  margin: 0 auto;
}

.title {
  @include heading();
  background-color: map-get($colors, foreground);
  text-align: center;
  margin-bottom: 1.5rem;
}

.message {
  @include copy();
  margin: 1.5rem;
  text-align: center;
}
