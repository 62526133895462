@import '../config';
@import '../mixins/box-shadow';
@import '../mixins/typography';
@import '../mixins/scroll';
@import '../mixins/link';

$overflow-padding: 2rem;

.container {
  width: 100%;
  flex-direction: row;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  &-title {
    @include heading();
  }

  &-desc {
    @include copy();
    @include type($size: small, $font: copy, $color: copy);
  }

  &-link {
    @include link();
  }
}

.scroll-container {
  position: relative;
  display: flex;
  width: calc(100% + map-get($map: $spacing, $key: sitePadding) * 2);
  margin-left: -(map-get($map: $spacing, $key: sitePadding));
  padding-left: map-get($map: $spacing, $key: sitePadding);
  padding-bottom: 1.5rem;
  overflow-x: hidden;

  &--server {
    overflow-x: auto;
  }

  @include breakpoint(xx-large, min) {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }

  &--with-scroll {
    @include scrollX();
  }

  &--hide-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &--content {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 400px;
  }

  > :nth-child(even),
  > :nth-child(odd) {
    flex: 1;
    margin-right: map-get($map: $spacing, $key: sitePadding);
  }

  > :last-child {
    @include breakpoint(xx-large, min) {
      margin-right: 0;
    }
  }
}

$button-size: 3.25rem;

.scroll-button {
  @include box-shadow();
  @include heading();
  color: map-get($map: $colors, $key: primary);
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(map-get($map: $colors, $key: foreground), 0.5);
  border-radius: 50%;
  width: $button-size;
  height: $button-size;
  cursor: pointer;

  @include breakpoint(xx-large, min) {
    display: flex;
  }

  &:hover {
    background-color: rgba(map-get($map: $colors, $key: foreground), 1);
  }

  &:active {
    box-shadow: none;
  }

  &--left {
    left: -(calc($button-size / 2) + map-get($map: $spacing, $key: sitePadding));
    padding-left: calc($button-size / 2);

    @include breakpoint(xx-large, min) {
      left: -(calc($button-size / 2.25));
      padding-left: 0;
    }
  }

  &--right {
    right: -(calc($button-size / 2) + map-get($map: $spacing, $key: sitePadding));
    padding-right: calc($button-size / 2);

    @include breakpoint(xx-large, min) {
      right: -(calc($button-size / 2));
      padding-right: 0;
    }
  }
}

.header-arrows {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin-left: 1rem;

  @include breakpoint(small, max) {
    display: none;
  }
}

.arrow-icon {
  cursor: pointer;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;

  svg {
    height: 2rem;
    width: 2rem;
  }

  &:hover {
    @include box-shadow();
  }

  &-right {
    grid-column: 2 / 3;
  }

  &-left {
    grid-column: 1 / 2;
  }
}

.header-right {
  display: flex;
  align-items: center;
  align-items: center;
}