@import "../config";
@import "../mixins/typography";
@import "../mixins/box-shadow";
@import "../mixins/link";
@import '../mixins/scroll';

.container {
  padding: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: map-get($spacing, "categoriesHeight");
  margin-bottom: -1rem;
  
    &-category-page {
      margin-bottom: -3.5rem !important;
    }

  @include breakpoint(large, max) {
    max-width: none;
    overflow-x: scroll;
  }
  
    @include breakpoint(small, min) {
    display: none;
  }

}

.pill-size {
  width: auto;
  white-space: nowrap;
  box-sizing: content-box;
  cursor: pointer;

  &-icon {
    height: 0;
    margin-top: -1rem;
    margin-right: -0.5rem !important;

  }
}

.bottom-sheet-container {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, "sitePadding");

  @include breakpoint(x-large, min) {
    display: none;
  }

  &-item {
    padding: 5px 0 5px 25px;
    min-width: 200px;
  }
}

.link-items {
  @include type($size: small, $color: copy, $font: copyBold);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  transition: all 0.5s;
  position: relative;

  &-text {
    width: auto;
  }

  &-bottom-sheet {
    justify-content: flex-start;
    height: 2rem;

    @include breakpoint(large, max) {
      @include type($size: base, $color: copy, $font: copy);
    }
  }

  &-active {
    @include type($size: small, $color: primary, $font: copyBold);
  }
}

.divider {
  border-bottom: 1px solid map-get($map: $colors, $key: borderLight);
  padding: 0 (map-get($spacing, sitePadding) * 2);
}