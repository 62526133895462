@import '../config/';
@import '../mixins/typography';

.container {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &--inline {
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 0.25rem;
    }
  }
}

.stars {
  display: flex;

  svg {
    min-width: 1.25rem;
    min-height: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  @include breakpoint(large, max) {
    justify-content: center;
  }

  &-no-shift {
    justify-content: flex-start;
    margin-right: 0.5rem;
  }
}

.count {
  display: inline-block;
  width: 100%;
  margin-top: 0.25rem;
  @include type($size: tiny, $color: copyLight);

  @include breakpoint(large, max) {
    width: auto;
  }
}
