@import '../config/';
@import '../mixins/loading';

.container {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    border-radius: 50%;
  }

  svg {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
