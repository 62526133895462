@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/link';
@import '../mixins/scroll';

.wrapper {
  padding-bottom: 2rem;

  h1 {
    @include type($font: copyBold, $size: jumbo);
  }

  h2 {
    @include type($font: copyBold, $size: medium);
  }

  h3 {
    @include type($font: copyBold, $size: small);
  }

  h4 {
    @include type($font: copyBold, $size: base);
  }

  p {
    @include type($font: copy, $size: base);
  }
}

.container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: auto;
  padding-left: map-get($spacing, 'sitePadding');
  padding-right: map-get($spacing, 'sitePadding');
  max-width: map-get($map: $spacing, $key: siteMaxWidth);
  margin: 0 auto;
  gap: 1rem;
}

.title {
  text-align: center;
  padding: 2rem;
}

.settings-list {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: foreground);
  padding: map-get($spacing, 'sitePadding');
  height: fit-content;
  @include box-shadow();
}

.selected-setting {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  margin-right: map-get($spacing, 'sitePadding');
}

.purchase-card {
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: foreground);
  padding: 1rem;
  margin: 0rem 0rem 1rem 0rem;
  @include box-shadow($withHover: true);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  .status {
    flex: 1;
  }

  .purchase-image {
    position: relative;
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);

    img {
      border-radius: map-get($map: $spacing, $key: borderRadius);
      overflow: hidden;
    }
  }

  .chevron {
    width: 1.5rem;
    height: 1.5rem;
  }
}

// selected order styles
.selected-order-card {
  background-color: map-get($map: $colors, $key: foreground);
  padding: map-get($map: $spacing, $key: sitePadding);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  @include box-shadow();
}

.details-container {
  display: flex;
  align-items: center;

  &--sm {
    margin: 2rem 0.5rem;
  }

  .title-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 1rem;
  }

  .details-title {
    @include type($font: copyBold, $size: large);
    padding-bottom: 0.5rem;

    &--sm {
      @include type($font: copyBold, $size: base);
    }
  }

  .author {
    @include type($font: copy, $size: base, $color: copy);

    &--sm {
      @include type($font: copy, $size: small, $color: copy);
    }
  }

  .format {
    @include type($font: copy, $size: base, $color: copyDark);
    padding-bottom: 0.25rem;

    &--sm {
      @include type($font: copy, $size: small, $color: copyDark);
    }
  }

  .details-divider {
    height: 2px;
    width: 100%;
    background-color: map-get($map: $colors, $key: background);
    margin: 0.25rem 0rem;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
  }

  .price {
    @include type($font: copy, $size: base, $color: copyDark);
    margin-right: 0.5rem;

    &--sm {
      @include type($font: copy, $size: small, $color: copyDark);
    }
  }

  .retail-price {
    text-decoration: line-through;
    @include type($font: copyLight, $size: small, $color: copy);

    &--sm {
      @include type($font: copyLight, $size: tiny, $color: copy);
    }
  }
}

.single-image {
  position: relative;
  width: 12rem;
  height: 12rem;
  min-width: 12rem;
  min-height: 12rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);

  &--sm {
    width: 6rem;
    height: 6rem;
    min-width: 6rem;
    min-height: 6rem;
  }

  img {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    overflow: hidden;
  }
}

.stacked-image-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

.stacked-image {
  position: relative;
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);

  &--sm {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
  }

  img {
    overflow: hidden;
  }

  &--tl img {
    border-top-left-radius: map-get($map: $spacing, $key: borderRadius);
  }

  &--tr img {
    border-top-right-radius: map-get($map: $spacing, $key: borderRadius);
  }

  &--bl img {
    border-bottom-left-radius: map-get($map: $spacing, $key: borderRadius);
  }

  &--br img {
    border-bottom-right-radius: map-get($map: $spacing, $key: borderRadius);
  }
}

.more-text {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  @include type($font: copy, $size: small, $color: copyDark);
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 0.25rem 0.5rem;
}

.divider {
  height: 2px;
  width: 100%;
  background-color: map-get($map: $colors, $key: background);
  margin: 0.25rem 0rem;
}

.section-title {
  @include type($font: copyBold, $size: medium, $color: copyDark);
  margin-top: 1.5rem;
}

.cost-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1rem;

  .cost-row-title {
    @include type($font: copy, $size: base, $color: copyDark);

    &-green {
      @include type($font: copy, $size: base);
      color: $success-500;
    }

    &-total {
      @include type($font: copyBold, $size: base, $color: copyDark);
    }
  }

  .cost-row-price {
    @include type($font: copy, $size: base, $color: copyDark);

    &-green {
      @include type($font: copy, $size: base);
      color: $success-500;
    }

    &-total {
      @include type($font: copyBold, $size: base, $color: copyDark);
    }
  }
}

.update-text {
  @include type($font: copy, $size: base, $color: copyDark);
  margin: 1rem 1rem;
}

.order-status {
  margin: map-get($map: $spacing, $key: sitePadding) 0;
}

.simple-profile-container {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;

  .simple-profile-avatar {
    position: relative;
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
    border-radius: 50%;
    margin-right: 1rem;

    img {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .simple-seller-name {
    h1 {
      @include type($font: copy, $size: medium, $color: copyDark);
    }

    h2 {
      @include type($font: copy, $size: base, $color: copy);
    }
  }
}

.my-profile-container {
  position: relative;
  background-color: map-get($map: $colors, $key: foreground);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 2rem 8rem;

  .edit-img-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: map-get($map: $colors, $key: foreground);
    padding: 0.75rem;
    border-radius: 50%;
    @include box-shadow($withHover: true);
    cursor: pointer;

    .icon {
      width: 1rem;
      height: 1rem;
    }
  }

  .spinner {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: map-get($map: $colors, $key: foreground);
    border-radius: 50%;
    padding: 0.5rem;
    @include box-shadow();
  }

  .my-profile-img {
    position: relative;
    width: 8rem;
    height: 8rem;
    min-width: 8rem;
    min-height: 8rem;
    border-radius: 50%;
    margin: 2rem auto;

    img {
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
  }
}

.my-address-container {
  background-color: map-get($map: $colors, $key: foreground);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 2rem 4rem;

  .name-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .address-line {
    margin-bottom: 1.5rem;
  }

  .city-state-zip {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1rem;
  }

  .address-save-btn {
    margin-top: 2rem;
  }
}

.my-methods-container {
  background-color: map-get($map: $colors, $key: foreground);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 2rem 4rem;

  .method-remove {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .sq-form-container {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .pm-title {
    margin-top: 2rem;
  }
}

.my-pw-container {
  background-color: map-get($map: $colors, $key: foreground);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 2rem 4rem;

  .verified-icon {
    path {
      fill: map-get($map: $colors, $key: green);
    }
  }

  .unverified-icon {
    path {
      fill: map-get($map: $colors, $key: yellow);
    }
  }

  .form-input {
    margin: 2rem 0rem;
  }

  .email-input-btn {
    margin-top: 2rem;
  }
}

.cancel-modal-container {
  margin-top: 1rem;

  .cancel-message {
    line-height: 1.5rem;
    @include type($font: copy, $size: base, $color: copy);
    text-align: center;
    margin-bottom: 2rem;
  }

  .cancel-error-title {
    @include type($font: copy, $size: large, $color: darkYellow);
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .input-container {
    margin: 1rem 0rem;
  }

  .cancel-btn {
    margin-top: 0.5rem;
  }

  textarea {
    width: 100%;
    height: 8rem;
    resize: none;
    outline: none;
    box-shadow: none;
    padding: 1rem;
    @include type($font: copy, $size: base, $color: copy);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }
}

.report-issue-container {
  margin-top: 1rem;

  .issue-thumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .issue-thumb-img {
      margin: 0rem 0.5rem;
      margin-bottom: 0.5rem;
      position: relative;

      img {
        border-radius: map-get($map: $spacing, $key: borderRadius);
      }

      .issue-thumb-close-icon {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: -5px;
        right: -5px;

        path {
          stroke: map-get($map: $colors, $key: gray);
          fill: white;
        }

        &:active {
          path {
            fill: map-get($map: $colors, $key: lightGray);
          }
        }
      }
    }
  }

  .message {
    @include type($font: copy, $size: base, $color: copy);
    text-align: center;
    line-height: 1.5rem;
  }

  .success-message {
    @include type($font: copy, $size: base, $color: copy);
    text-align: center;
    padding-bottom: 1rem;
    line-height: 1.5rem;
  }

  .form {
    margin: 1rem 0rem;
  }

  .button {
    margin-top: 1rem;
  }

  textarea {
    width: 100%;
    height: 8rem;
    resize: none;
    outline: none;
    box-shadow: none;
    padding: 1rem;
    @include type($font: copy, $size: base, $color: copy);
    border-radius: map-get($map: $spacing, $key: borderRadius);
    margin-bottom: 1rem;
  }

  .type-selection {
    display: grid;
    grid-template-columns: auto auto;
    margin: 1rem 0rem;
    background-color: map-get($map: $colors, $key: background);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  .pill {
    cursor: pointer;
  }

  .error {
    margin-top: 1rem;
    color: map-get($map: $colors, $key: darkYellow);
  }
}

.review-li {
  margin-top: 1rem;
  position: relative;

  .check-icon {
    path {
      fill: map-get($map: $colors, $key: primary);
    }
  }
}

.avatarFileInput {
  display: none;
}

.profileForm {
  display: block;
  margin: 2rem 0;

  .profileInput {
    margin-bottom: 1rem;
  }

  .inputGroup {
    margin: 0.5rem 0;
  }

  .profileUpdateBtn {
    margin-top: 1rem;
  }
}

.mobileSettingSelector {
  display: none;
}

.settingOption {
  margin: 0.5rem 0;
}

.settingSelect {
  position: relative;

  select {
    @include box-shadow();
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background: map-get($colors, foreground);
    border: 1px solid map-get($colors, borderLight);
    padding: 2rem 4rem;
  }
}

@include breakpoint(large, max) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .settings-list {
    display: none;
  }

  .selected-setting {
    margin: 0 1rem;
  }

  .mobileSettingSelector {
    position: relative;
    display: block;
    margin: 0 1rem;
  }
}

@include breakpoint(medium, max) {
  .container {
    box-shadow: none;
  }

  .settingSelect {
    position: relative;

    select {
      box-shadow: none;
      border-radius: none;
      padding: 1rem;
      width: 100%;
    }
  }

  .profileForm {
    margin: 0;
  }

  .my-profile-container {
    box-shadow: none;
    border-radius: none;
    padding: 1rem 1.5rem;
    width: 100%;

    @include breakpoint(medium, max) {
      padding-top: 2rem;
    }

    .my-profile-img {
      margin-top: 0.5rem;
    }
  }

  .my-address-container {
    box-shadow: none;
    border-radius: none;
    padding: 1rem 1.5rem;
    width: 100%;

    .city-state-zip {
      display: block;

      & > div {
        margin-bottom: 1.5rem;
      }
    }
  }

  .my-pw-container {
    box-shadow: none;
    border-radius: none;
    padding: 1rem 1.5rem;
  }

  .my-methods-container {
    box-shadow: none;
    border-radius: none;
    padding: 1rem 1.5rem;
  }

  .details-container {
    flex-direction: column;

    .title-wrapper {
      margin-top: 1rem;
    }
  }
}

.loading-text {
  @include type($font: copy, $size: base, $color: copy);
  text-align: center;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.see-profile {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;

  .see-profile-text {
    color: map-get($map: $colors, $key: primary);

    &:active {
      color: map-get($map: $colors, $key: copyDark);
    }
  }

  .see-profile-icon {
    margin-left: 0.5rem;

    &:active {
      path {
        stroke: map-get($map: $colors, $key: copyDark);
      }
    }

    width: 1rem;
    height: 1rem;
  }
}

.msg-component-container {
  margin-top: 1rem;

  .see-messenger-container {
    margin-top: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;

    .see-messenger-text {
      color: map-get($map: $colors, $key: primary);
    }

    .see-messenger-icon {
      margin-left: 0.25rem;

      path {
        stroke: map-get($map: $colors, $key: primary);
      }
    }
  }

  p {
    text-align: center;
  }

  a {
    @include link();
    color: map-get($map: $colors, $key: primary);
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.recent-msg-title {
  @include type($font: copyBold, $size: base, $color: copyDark);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.recent-messages-list {
  max-height: 40vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  @include scrollY();
  flex: 1;
}

.card-title {
  padding-bottom: 0.25rem;
}

.card-author {
  @include type($font: copy, $size: small, $color: copy);
  padding-bottom: 0.5rem;
}

.card-details {
  text-align: left;
  flex: 1;
  padding: 1rem;
}

.noti-container {
  height: 1rem;
  width: 1rem;
}

.review-stars {
  max-width: 8rem;
}

.modal {
  &-container {
    padding: map-get($spacing, 'sitePadding');
  }

  &-text {
    @include type($font: copy, $size: base, $color: copy);
    text-align: center;
    padding-bottom: map-get($spacing, 'sitePadding');
  }

  &-packageName {
    @include type($font: copyBold, $size: base, $color: copyDark);
  }

  &-button {
    margin-bottom: map-get($spacing, 'sitePadding');
  }
}
