@import "../../config";
@import "../../mixins/typography";
@import "../../mixins/box-shadow";
@import "../../mixins/tile";

.tile {
  @include tile-base();

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  img {
    min-width: 100%;
  }
}