@import "../config";
@import "../mixins/typography";
@import "../mixins/box-shadow";

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .badge {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 100%;
    @include box-shadow();
    margin-right: 0rem;

    &--shipment {
      background-color: map-get($map: $colors, $key: primary);
    }

    &--canceled {
      background-color: map-get($map: $colors, $key: yellow);
    }

    &--shipped {
      background-color: map-get($map: $colors, $key: green);
    }

    &--delivered {
      background-color: map-get($map: $colors, $key: green);
    }
  }

  .text {
    margin-left: 1rem;
    @include type($font: copy, $size: base, $color: copyDark);
  }
}
