@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/box-shadow';

$header-container-height: 115px; //includes notification container padding, header height and tab component

.container {
  position: relative;
}

.notifications-container {
  padding: 1rem;
  text-align: start;

  .notifications-header {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.title {
  @include type($size: medium, $font: copyBold, $color: copyDark);
}

.tab-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  margin: 1rem 0;
  padding: 0.25rem;
  text-align: center;
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.tab-item {
  transition: all 0.25s;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: 0.5rem 0rem;
  cursor: pointer;
  @include type($size: base, $font: copy, $color: copy);

  &--selected {
    background-color: lighten(map-get($map: $colors, $key: primary), 35%);
    @include type($size: base, $font: copy, $color: primary);
  }
}

.messages-list {
  display: none;
  transition: all 0.25s;
  max-height: 50vh;
  overflow: auto;

  &--show {
    display: block;
  }

  @include breakpoint(large, max) {
    max-height: calc(
      100vh -
        (
          map-get($map: $spacing, $key: 'mobileNavHeight') + $header-container-height
        )
    );
  }
}

.notifications-list {
  display: none;
  transition: all 0.25s;
  max-height: 50vh;
  overflow: auto;

  hr {
    border: 1px solid map-get($map: $colors, $key: light);
  }

  &--show {
    display: block;
  }

  @include breakpoint(large, max) {
    max-height: calc(
      100vh -
        (
          map-get($map: $spacing, $key: 'mobileNavHeight') + $header-container-height
        )
    );
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-title {
  @include type($size: base, $font: copyBold, $color: copyDark);
  text-align: center;
  margin-top: 2rem;
}

.empty-icon {
  margin-top: 1rem;
  width: 2.5rem;
  margin-bottom: 2rem;

  path {
    stroke: map-get($map: $colors, $key: copy);
  }
}

.noti-container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin: 0.25rem 0;
  padding: 0.75rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  transition: background-color 100ms ease-out;

  .noti-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .noti-img {
    position: relative;
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 0.75rem;
    cursor: pointer;
    margin-right: 0.75rem;

    &-review {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      border-radius: inherit;
      overflow: hidden;
    }
  }

  .review-icon {
    width: 2rem;
    height: 2rem;
  }

  .noti-title-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .noti-title {
    flex: 1;
    @include copy();
    @include type($size: small, $font: copyBold, $color: copyDark);
  }

  .noti-time {
    @include type($size: tiny, $font: copy, $color: copy);
    padding: 0.4rem 0rem 0rem 0.5rem;
  }

  .noti-message {
    @include copy();
    @include type($size: tiny, $font: copy, $color: copy);
  }

  &:hover {
    background-color: lighten(map-get($map: $colors, $key: primary), 35%);
  }

  &:hover .noti-title {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &:hover .noti-time {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &:hover .noti-message {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }
}

.new-title {
  @include type($size: base, $font: copy, $color: copy);
  margin: 0.5rem 1rem;
  padding-top: 0.5rem;
}

.noti-modal-button {
  margin-top: 10px;
}

.new-title {
  @include type($size: base, $font: copy, $color: copy);
  margin: 0.5rem 1rem;
  padding-top: 0.5rem;
}

.msg-loading-container {
  margin: 1rem 2rem;

  .details {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: map-get($map: $colors, $key: borderLight);
  }

  .name-txt-container {
    width: 70%;
    margin-left: 1rem;
  }

  .name {
    width: 5rem;
    height: 1rem;
    background: map-get($map: $colors, $key: borderLight);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  .text {
    margin: 0.25rem 0rem;
    margin-top: 0.5rem;
    width: 100%;
    height: 0.75rem;
    background: map-get($map: $colors, $key: borderLight);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  .text-2 {
    margin: 0.25rem 0rem;
    width: 70%;
    height: 0.75rem;
    background: map-get($map: $colors, $key: borderLight);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }
}
