@import "../config";
@import "../mixins/typography";

.review-img {
    width: 2rem;
    height: 2rem;
}

.header {
    @include type($size: large, $font: copyBold, $color: copyDark);
    text-align: center;
}

.btn {
    margin: 1rem 0rem 0rem 0rem;
}

.stars-cont {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.time {
    @include type($size: tiny, $font: copyLight, $color: copy);
}
.name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    @include type($size: small, $font: copyBold, $color: copyDark);
}

.reply {
    @include type($size: small, $font: copyDark, $color: copyDark);
    margin-left: 1rem;
    line-height: 1.5;
}

.reply-img {
    width: 2rem;
    height: 2rem;

}

.reply-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.reply-name {
    @include type($size: small, $font: copyBold, $color: copyDark);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reply-time {
    @include type($size: tiny, $font: copy, $color: copy);
    margin-bottom: 0.5rem;
}

.photo-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.reply-header {
    margin-top: 0.5rem;
}

.reply-btn {
    width: 50%;
    @include type($size: tiny, $font: copy, $color: copy);
}