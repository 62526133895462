@import "../../config";
@import "../../mixins/typography";
@import "../../mixins/truncate";
@import "../../mixins/loading";

.title {
  @include type($font: copyBold, $size: small);
  @include truncate(2);
  margin-bottom: 0.5rem;

  @include breakpoint(medium, max) {
    margin-bottom: 0.25rem;
  }
}

.author {
  @include type($font: copy, $size: tiny, $color: copyLight);
  @include truncate(1);
  justify-self: start;
}

.image-container {
  @include skeleton();
  border-radius: 0;
  position: relative;
  background-color: map-get($map: $colors, $key: background);
  height: 12rem;

  @include breakpoint(large, max) {
    height: 10rem;
  }

  img {
    object-fit: cover;
  }
}

.seller-info {
  padding: .5rem .75rem;
  padding-bottom: .25rem;
  display: flex;
  flex-direction: row;
  background-color: map-get($map: $colors, $key: background);
  border-bottom: 1px solid map-get($map: $colors, $key: borderLight);

  &-image {
    img {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &-content {
    margin-left: .5rem;
    max-width: 70%;

    h2 {
      @include type($size: "tiny", $font: copyBold);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      min-width: .75rem;
      max-width: .75rem;
      min-height: .75rem;
      max-height: .75rem;
    }
  }
}


.action-row {
  padding-bottom: 0;
  flex: 1;
  display: "flex";
  flex-direction: row;
  display: inline-block;
}

.wish-position {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.wish {
  width: 1.3rem;
  height: 1.3rem;
  transition: all .25s;

  @include breakpoint(medium, max) {
    width: 1.2rem;
    height: 1.2rem;
  }

  svg {
    width: 1.3rem;
    height: 1.3rem;

    @include breakpoint(medium, max) {
      width: 1.2rem;
      height: 1.2rem;
    }

    path {
      transition: all .25s;

      &:hover {
        fill-opacity: 0.5;
      }
    }
  }
}

.cart-position {
  justify-self: end;
}

.cart {
  display: inline-block;
  transition: all .25s;

  width: 2.5rem;
  height: 2.5rem;

  @include breakpoint(medium, max) {
    width: 2rem;
    height: 2rem;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;

    @include breakpoint(medium, max) {
      width: 2rem;
      height: 2rem;
    }

    rect {
      transition: all .25s;

      &:hover {
        fill-opacity: 0.1;
      }
    }
  }
}

.content-container {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.info {
  justify-self: start;
  @include truncate(1);
  word-wrap: break-word;
  word-break: break-word;

  &-price {
    @include type($font: copy, $size: small);

    &-drop {
      @include type($font: copy, $size: tiny, $color: copyLight);
      margin-left: .25rem;
      text-decoration: line-through !important;
    }
  }
}

.tile-small {
  display: block;
  width: auto;

  .image-container {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    overflow: hidden;
    width: 5rem;
    height: 5rem;
  }
}

.content-bottom {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 0.25rem;
  align-items: center;

  &--large {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
  }
}

.content-top {
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 0.25rem;
  align-items: center;

  @include breakpoint(medium, max) {
    margin-bottom: 0.25rem;
  }
}

.sales-icon-container {
  justify-self: end;
  display: flex;
  align-items: center;
}

.info-icon {
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.sold-banner {
  background-color: map-get($map: $colors, $key: yellow);
  position: absolute;
  z-index: 1;
  width: 100%;
  text-align: center;
  top: 80%;
  padding: 5px;
  opacity: 0.8;
}

.sold-text {
  @include type($font: copyBold, $size: small, $color: copyDark);
}