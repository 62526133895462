@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/inputs';
@import '../mixins/scroll';
@import '../mixins/link';
@import '../mixins/truncate';

.container {
  position: relative;
  flex-basis: 100%;
  @include input-reset();
}

.input-container {
  position: relative;

  form {
    position: relative;
  }
}

.input {
  @include input();
  z-index: 2;
  width: 100%;

  &-active {
    background-color: map-get($map: $colors, $key: foreground);
  }

  @include breakpoint(medium, min) {
    &-active {
      border-bottom: 1px solid transparent !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.input-clear {
  @include copy();
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid map-get($map: $colors, $key: borderLight);
  background-color: map-get($map: $colors, $key: background);
  border-top-right-radius: map-get($map: $spacing, $key: borderRadius);
  border-bottom-right-radius: map-get($map: $spacing, $key: borderRadius);
  transition: background-color 0.25s;

  &--active {
    border-bottom: none;

    @include breakpoint(medium, min) {
      border-bottom-right-radius: 0;
    }
  }

  &:hover {
    background-color: darken(map-get($map: $colors, $key: background), 10%);
  }
}

.input-suggestion {
  @include type($color: primary);
  z-index: 0;
  position: absolute;
  z-index: -1;
  left: 21px;
  top: 15px;

  &-active {
    opacity: 0;
  }

  &-inactive {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.suggestions-container {
  @include box-shadow();
  @include scrollY();

  border-top: 1px solid map-get($map: $colors, $key: borderLight);
  background-color: map-get($map: $colors, $key: foreground);
  width: 100%;
  position: fixed;
  top: map-get($map: $spacing, $key: mobileNavHeight);
  left: 0;
  height: calc(100vh - map-get($map: $spacing, $key: mobileNavHeight));
  z-index: -1;

  @include breakpoint(medium, min) {
    position: absolute;
    top: 100%;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: auto;
    max-height: calc(100vh - map-get($map: $spacing, $key: mobileNavHeight));
  }
}

.suggestion-heading {
  @include heading($font-size: base);
  padding-left: 1.25rem;
  margin-top: 1rem;
}

.suggestion-hashtags {
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem map-get($map: $spacing, $key: sitePadding) 1rem
    map-get($map: $spacing, $key: sitePadding);
}

.suggestion-query {
  @include type($size: small);
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  width: 100%;
  display: block;

  &:focus,
  &:hover {
    border: none;
    outline: none;
    @include type($size: small, $font: copyBold, $color: primary);
  }

  :hover {
    @include type($size: small, $font: copyBold);
  }
}

.suggestion-book {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  transition: background-color 0.15s ease-out;
  position: relative;
  justify-content: space-between;

  button {
    width: auto;
    min-width: 5rem;
  }

  &-container {
    margin-bottom: 0;
  }

  &-content {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &-image {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    min-width: 3.5rem;
    border-radius: 0.75rem;
    overflow: hidden;
    background: map-get($map: $colors, $key: background);

    &-selected {
      position: absolute;
      top: 0.5rem;
      left: 1rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: map-get($map: $colors, $key: green);
      border: 2px solid white;
      z-index: 1;
    }
  }

  &-active,
  &:focus {
    border: none;
    outline: none;
    background-color: map-get($map: $colors, $key: borderLight);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &-info {
    margin-left: 1rem;

    h3 {
      @include type($font: copyBold, $size: small);
      @include truncate(1);
      margin-bottom: 0.25rem;
    }

    h4 {
      @include type($size: tiny, $color: copyDark, $font: copy);
      @include truncate(1);
      line-height: 1.3;
    }

    h5 {
      margin-top: 0.25rem;
      @include type($size: tiny, $color: copy, $font: copy);
    }
  }
}

.suggestion-author-SVG {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.suggestion-user {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  transition: background-color 0.15s ease-out;

  &-active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
    background-color: map-get($map: $colors, $key: borderLight);
  }

  &-image {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    min-width: 3.5rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    overflow: hidden;
    background: map-get($map: $colors, $key: background);

    &-round {
      border-radius: 50%;
    }
  }

  &-reviews {
    $size: 1rem;

    svg {
      min-width: $size !important;
      min-height: $size !important;
      max-width: $size !important;
      max-height: $size !important;
    }
  }

  &-callout {
    display: flex;
    align-items: center;

    h3 {
      margin-right: 0.25rem;
    }

    span {
      margin-left: 0.25rem;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &-info {
    margin-left: 1rem;

    h3 {
      @include type($font: copyBold, $size: small);
      @include truncate(1);
    }

    h4 {
      @include type($size: tiny);
      @include truncate(1);
      margin-top: 0.25rem;
    }
  }
}

.no-results {
  padding: 2.5rem;
  padding-top: 1rem;
  text-align: center;

  h2 {
    @include heading();
    margin-bottom: 1rem;
  }

  p {
    @include copy();

    span {
      @include type($font: copyBold);
    }
  }
}

.show-more {
  @include copy($font-size: small);
  padding: 1rem 2rem;
  width: 100%;
  text-align: center;

  a {
    @include link();
    display: inline-block;
    width: 100%;
  }
}

.multiple-book-search-link {
  @include copy($font-size: small);
  padding: 1rem 2rem;
  width: 100%;
  text-align: left;
  margin-top: 1rem;

  a {
    @include link();
    display: inline-block;
    width: 100%;
  }
}

.search-icon {
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: calc(50% - 0.6rem);
  right: 0.75rem;
}

.author-img {
  border-radius: 50%;
}

.callout-multiple-books-icon {
  width: 60px;
  height: 60px;
  align-self: center;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
