@import '../config';
@import '../mixins/typography';
@import '../mixins/link';

.session-form {
  margin: 0 2rem;

  @include breakpoint(small, max) {
    margin: 0;
  }

  a {
    @include link;
  }

  h2 {
    @include type($size: medium, $font: copyBold, $color: copy);
    text-align: center;
  }

  h4 {
    @include type($size: base, $font: copy, $color: copy);
    text-align: center;
    margin: 0.5rem 0;
  }

  p {
    @include copy($font-size: small);
    text-align: center;
  }

  &-social-logins {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-forgot-password {
    text-align: end;
    margin-top: 0.5rem;
    @include type($size: small, $font: copy, $color: copy);

    a {
      text-decoration: underline;
      color: map-get($map: $colors, $key: gray);
    }
  }

  &-terms {
    @include type($size: small, $font: copy, $color: copy);
    margin-top: 1.5rem;
  }

  &-switch {
    margin-top: 1.5rem;
  }
}

.verify-form {
  margin: 0 2rem;

  @include breakpoint(small, max) {
    margin: 0;
  }

  input {
    margin-top: 1.5rem;
  }

  h2 {
    @include type($size: medium, $font: copySemiBold, $color: copy);
    text-align: center;
    line-height: 1.5;
  }

  p {
    @include type($size: base, $font: copy, $color: copy);
    text-align: center;
    line-height: 1.5;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  &-resend-text {
    margin-top: 1.5rem;

    span {
      @include type($color: primary);
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        @include type($color: primary);
      }
    }
  }
}

input.input-error {
  margin-bottom: 0.25rem;
}

.separator {
  position: relative;
  display: block;
  @include type($size: small, $font: copy, $color: copy);
  margin: map-get($map: $spacing, $key: sitePadding) 0;
  text-align: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    width: 45%;
    height: 1px;
    background-color: map-get($map: $colors, $key: lighterGrey);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
