@import "../config";
@import "../mixins/typography";
@import "../mixins/box-shadow";

@mixin tile-base() {
  box-shadow: 0px 1px 0.25em 0 rgba(map-get($map: $colors, $key: copy), 0.35);
  transition: box-shadow 0.35s;

  &:hover {
    box-shadow: 0px 3px 0.75em 0 rgba(map-get($map: $colors, $key: copy), 0.35);
  }

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  background-color: map-get($map: $colors, $key: foreground);

  border-radius: map-get($map: $spacing, $key: borderRadius);
  cursor: pointer;

  img {
    border-top-right-radius: map-get($map: $spacing, $key: borderRadius);
    border-top-left-radius: map-get($map: $spacing, $key: borderRadius);
  }

  min-width: 11rem;
  max-width: 11rem;

  @include breakpoint(large, max) {
    min-width: 9rem;
    max-width: 9rem;
  }
}