@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/box-shadow';
@import '../../mixins/tile';
@import '../../mixins/loading';

.tile {
  @include tile-base();

  @include breakpoint(medium, max) {
    min-width: 9rem;
    max-width: 9rem;
  }

  cursor: progress;
  height: 320px;
  background-color: map-get($map: $colors, $key: foreground);
  display: flex;
  flex-direction: column;

  @include breakpoint(medium, max) {
    height: 240px;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  height: 100%;
}

.image-container {
  padding: 0.5rem;

  * {
    @include skeleton();
    height: 11rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include breakpoint(medium, max) {
      height: 8rem;
    }
  }
}

.title {
  * {
    @include skeleton();
    height: 1rem;
    margin-bottom: 0.5rem;

    @include breakpoint(medium, max) {
      height: 0.75rem;
      margin-bottom: 0.25rem;
    }
  }

  :last-child {
    width: 90%;
  }
}

.author {
  @include skeleton();
  max-width: 70%;
  height: 0.75rem;
  margin-bottom: 0.5rem;

  @include breakpoint(medium, max) {
    height: 0.5rem;
    margin-bottom: 0.25rem;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .price {
    display: flex;
    align-items: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.25rem;

      @include breakpoint(medium, max) {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    :last-child {
      @include skeleton();
      width: 4rem;
      height: 1rem;

      @include breakpoint(medium, max) {
        height: 0.75rem;
      }
    }
  }
}

.like {
  margin-left: auto;
  width: 1.5rem;
  transition: all 5s;

  @include breakpoint(medium, max) {
    width: 1.25rem;
  }

  svg {
    fill {
      transition: all 5s;
    }

    width: 1.5rem;

    @include breakpoint(medium, max) {
      width: 1.25rem;
    }
  }
}

// Styles for seller bookstore loading tile
.tile--seller {
  @include tile-base();
  cursor: progress;
  background-color: map-get($map: $colors, $key: foreground);

  min-width: 25rem;
  width: 25rem;
  height: 340px;
  padding: 1rem;

  display: flex;
  flex-direction: column;

  .content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    height: 3rem;

    :nth-child(1) {
      @include skeleton();
      min-width: 4rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      margin-right: 1.5rem;
    }

    :nth-child(2) {
      @include skeleton();
      height: 0.75rem;
      position: relative;
      margin-right: 2rem;
      transform: translateY(-0.75rem);

      &:before {
        @include skeleton();
        content: '';
        position: absolute;
        height: 0.75rem;
        top: 1.5rem;
        width: 80%;
      }
    }

    :nth-child(3) {
      @include skeleton();
      width: 75%;
      height: 3rem;
      margin-left: auto;
    }
  }

  .image-container {
    position: relative;
    height: 15rem;
    gap: map-get($map: $spacing, $key: sitePadding) * 0.25;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    margin-bottom: 1rem;
    overflow: hidden;

    * {
      border-radius: 0;
    }

    :nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      width: 244px;
      height: 100%;
    }

    :nth-child(2) {
      position: absolute;
      top: 0;
      right: 0;
      height: 118px;
      width: 120px;
    }

    :nth-child(3) {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 118px;
      width: 120px;
    }
  }
}

// Styles for content loading tile
.tile--content {
  @include tile-base();
  cursor: progress;
  background-color: map-get($map: $colors, $key: foreground);

  min-width: 26rem;
  width: 26rem;
  height: 17rem;
  padding: 1rem;

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 3rem;

    :nth-child(1) {
      @include skeleton();
      height: 0.75rem;
      position: relative;
      width: 30%;
      transform: translateY(-0.75rem);
    }

    :nth-child(2) {
      @include skeleton();
      height: 0.75rem;
      position: relative;
      transform: translateY(-0.75rem);
    }
  }

  .image-container {
    position: relative;
    height: 11rem;
    gap: map-get($map: $spacing, $key: sitePadding) * 0.25;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    margin-bottom: 1rem;
    overflow: hidden;

    * {
      border-radius: 0;
    }

    :nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      width: 24rem;
      height: 100%;
    }
  }

  @include breakpoint(large, max) {
    min-width: 26rem;
    width: 26rem;
  }
}
