@import "../config";
@import "../mixins/typography";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 0.25rem;
  background-color: map-get($map: $colors, $key: primary);
  transition: width 0.3s, opacity 0.5s;
  z-index: 99;
}
