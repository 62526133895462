@import "../config";
@import "../mixins/typography";
$spacing: 1.5rem;

.reset-password-form {
  margin-top: $spacing;
  
  h1 {
    @include heading;
    text-align: center;
    margin-bottom: $spacing;
  }

  button {
    margin-top: $spacing;
  }

}
