@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';
@import '../mixins/inputs';
@import '../mixins/link';
@import '../mixins/scroll';

.wrapper {
  h1 {
    @include type($font: copyBold, $size: large);
  }

  h2 {
    @include type($font: copyBold, $size: medium);
  }

  h3 {
    @include type($font: copyBold, $size: small);
  }

  h4 {
    @include type($font: copyBold, $size: base);
  }
}

.title {
  text-align: center;
  margin: 2rem 0rem;

  @include breakpoint(large, max) {
    display: none;
  }
}

.content-container {
  position: absolute;
  top: calc(map-get($map: $spacing, $key: categoryHeight) * 2.75);
  bottom: 0;
  display: grid;
  grid-template-columns: 20rem auto;
  grid-template-rows: 100%;
  min-width: 100%;
  overflow: hidden;

  @include breakpoint(large, min) {
    @include box-shadow();
  }

  @include breakpoint(large, max) {
    position: absolute;
    // z-index: 10;
  }
}

.recent-msg-container {
  background: map-get($map: $colors, $key: foreground);
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border-right: 1px solid map-get($map: $colors, $key: borderLight);

  @include breakpoint(large, max) {
    display: none;
  }
}

.recent-msg-wrapper {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.msg-detail-container {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-color: map-get($map: $colors, $key: background);
  border-top-right-radius: map-get($map: $spacing, $key: borderRadius);
  border-bottom-right-radius: map-get($map: $spacing, $key: borderRadius);

  @include breakpoint(large, max) {
    grid-column: 1 / 3;
  }
}

.msg-detail-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  position: relative;
}

.recent-msg-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid lighten(map-get($map: $colors, $key: lightGray), 20%);

  button {
    width: auto;
  }
}

.new-msg-info {
  @include type($font: copy, $size: tiny, $color: copyLight);
  padding: 0 1rem;
  margin: 0.5rem 0;
  text-align: center;
  border-bottom: 1px solid lighten(map-get($map: $colors, $key: lightGray), 20%);
  padding-bottom: 0.75rem;
}

.new-msg-icon-box {
  border: 2px solid lighten(map-get($map: $colors, $key: lightGray), 20%);
  padding: 0.4rem;
  border-radius: 8px;
  cursor: pointer;
}

.new-msg-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.recent-msgs {
  @include scrollY();
  padding: map-get($map: $spacing, $key: sitePadding) / 2;

  .msg-list-item-wrapper {
    margin-bottom: map-get($map: $spacing, $key: sitePadding) / 2;
  }
}

// message list item
.msg-list-item-wrapper {
  border-radius: map-get($map: $spacing, $key: borderRadius);
  padding: map-get($map: $spacing, $key: sitePadding);

  &--simple {
    border-bottom: none;
  }

  cursor: pointer;

  .msg-copy {
    @include copy();
    @include type($font: copy, $color: copyLight, $size: tiny);
    margin-top: 0.25rem;
  }

  .msg-name {
    @include type($font: copyBold, $size: small);
    margin-bottom: 0.25rem;
  }

  .msg-username {
    @include type($font: copy, $size: tiny, $color: copyDark);
  }

  .msg-time {
    @include type($font: copy, $size: tiny, $color: copy);
  }

  &:hover {
    background-color: map-get($map: $colors, $key: borderLight);
  }

  &:hover .msg-name {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &:hover .msg-username {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &:hover .msg-time {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &:hover .msg-copy {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &--active {
    background-color: map-get($map: $colors, $key: borderLight);
  }

  &--active .msg-name {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &--active .msg-username {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &--active .msg-time {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  &--active .msg-copy {
    color: darken(map-get($map: $colors, $key: primary), 20%);
  }

  .simple {
    margin-left: 0px;
    margin-top: 0.25rem;
  }
}

.user-list-item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.unread-badge {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  opacity: 0;
  border-radius: 50%;
  margin-right: 0.5rem;
  @include box-shadow();

  &--visible {
    background-color: map-get($map: $colors, $key: primary);
    opacity: 1;
  }
}

.msg-avatar {
  position: relative;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  border-radius: 50%;
  cursor: pointer;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}

.msg-detail {
  flex: 1;
  margin: 0rem 1rem;
}

// Message Details
.user-avatar {
  position: relative;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 50%;
  cursor: pointer;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}

.user-summary {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  background-color: map-get($map: $colors, $key: foreground);
  border-bottom: 1px solid map-get($map: $colors, $key: borderLight);
}

.user-details {
  display: flex;
  align-items: center;
}

.user-name-container {
  margin: 0rem 0.75rem;
}

.user-name {
  @include type($font: heading, $size: medium, $color: copyDark);
}

.user-username {
  margin-top: 0.25rem;
  @include type($font: copy, $size: small, $color: copyDark);
}

.user-btn-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  gap: 1rem;
}

.messages {
  @include scrollY();
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 map-get($map: $spacing, $key: sitePadding);
  padding-top: 1rem;
  padding-bottom: 8rem;
}

.message-container {
  margin: 1rem 0;
  display: flex;
  align-items: flex-end;
  max-width: 60%;

  @include breakpoint(large, max) {
    max-width: none;
  }

  &--theirs {
    .msg-detail-avatar {
      order: 0;
      margin-right: 1rem;
    }
  }

  &--mine {
    float: right;
    align-self: flex-end;

    .msg-detail-avatar {
      order: 2;
      margin-left: 1rem;
    }

    .msg-metadata-time {
      text-align: right;
    }
  }
}

.message {
  position: relative;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;

  &-content {
    color: white;
    margin: 0;
  }

  &-image {
    margin-bottom: 0.5rem;
    border-radius: 4px;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
    line-height: 0;
  }

  &--mine {
    background-color: map-get($map: $colors, $key: primary);
    text-align: right;
    border-bottom-right-radius: 0px;
  }

  &--mine.message--image-only {
    background-color: transparent;
    padding: 0;

    .message-image {
      border: 4px solid darken(map-get($map: $colors, $key: primary), 10%);
      border-radius: 8px;
      margin-bottom: 0;
    }
  }

  &--theirs {
    background-color: #e9ecef;
    border-bottom-left-radius: 0px;

    .message-content {
      color: #212529;
    }
  }
}

.msg-wrapper {
  position: relative;
}

.msg-metadata {
  margin-bottom: 0.25rem;
}

.msg-detail-avatar {
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  min-width: 2.75rem;
  min-height: 2.75rem;
  cursor: pointer;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}

.msg-metadata-name {
  @include type($font: copyBold, $size: tiny, $color: copyDark);
  margin-right: 1rem;
}

.msg-metadata-time {
  @include type($font: copy, $size: tiny, $color: copy);
}

.msg-input-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: map-get($map: $spacing, $key: sitePadding);
  background-color: map-get($map: $colors, $key: foreground);
  border-top: 2px solid darken(map-get($map: $colors, $key: borderLight), 20%);
}

.msg-book-tile {
  padding-bottom: 0.25rem;
}

.input-img-book {
  padding-right: 1rem;
}

$send-button-width: 5rem;

.msg-input {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: foreground);
}

.input-fields-container {
  display: flex;
  align-items: center;
  position: relative;
}

.msg-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.input-container {
  position: relative;
  flex: 1;
}

.input-field {
  @include input-reset();
  @include type($font: copy);
  background-color: map-get($map: $colors, $key: background);
  width: 100%;
  border: 1px solid darken(map-get($map: $colors, $key: borderLight), 15%);
  resize: none;
  outline: none;
  box-shadow: none;
  padding: map-get($map: $spacing, $key: sitePadding);
  padding-right: 80px;
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.send-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  button {
    width: $send-button-width;
  }
}

.upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;

  &:hover {
    background-color: map-get($map: $colors, $key: borderLight);
  }
}

.image-preview-container {
  margin: 0.5rem 0;
}

.image-preview {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  overflow: hidden;

  img {
    display: block;
    object-fit: cover;
  }
}

.remove-image-btn {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  line-height: 1;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

// no messages
.no-msgs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.no-msgs-outer-wrapper {
  padding: 1rem;
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.no-msgs-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 3rem 3rem 3rem;
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.no-msgs-icon {
  width: 5rem;
  height: 5rem;
  margin: 2rem;
}

.no-msgs-title {
  padding-bottom: 1rem;
}

.msg-name-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loading-left {
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: borderLight);
  width: 15rem;
  padding: 1.5rem 3rem;
  margin: 1rem 4rem;
  border-top-left-radius: 0rem;
}

.loading-right {
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: borderLight);
  width: 15rem;
  padding: 1.5rem 3rem;
  margin: 1rem 4rem;
  border-top-right-radius: 0rem;
  align-self: flex-end;
}

.recent-msg-loading-container {
  margin: 1rem 2rem;

  .details {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: map-get($map: $colors, $key: borderLight);
  }

  .name {
    margin-left: 1rem;
    width: 5rem;
    height: 1rem;
    background: map-get($map: $colors, $key: borderLight);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  .text {
    margin: 1rem 0rem;
    width: 100%;
    height: 1rem;
    background: map-get($map: $colors, $key: borderLight);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  .text-2 {
    margin: 1rem 0rem;
    width: 70%;
    height: 1rem;
    background: map-get($map: $colors, $key: borderLight);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }
}

.order-card {
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: foreground);
  padding: 1rem;
  margin: 0rem 0rem 1rem 0rem;
  @include box-shadow($withHover: true);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @include breakpoint(large, max) {
    padding-left: 0.25rem;
  }

  .order-status {
    flex: 1;
  }

  .order-chevron {
    width: 1.5rem;
    height: 1.5rem;

    @include breakpoint(large, max) {
      display: none;
    }
  }
}

.input-img-order {
  padding-right: 1rem;
  cursor: pointer;
}

.msg-component-container {
  .see-messenger-container {
    margin-top: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;

    .see-messenger-text {
      color: map-get($map: $colors, $key: primary);
    }

    .see-messenger-icon {
      margin-left: 0.25rem;

      path {
        stroke: map-get($map: $colors, $key: primary);
      }
    }
  }

  p {
    text-align: center;
  }

  a {
    @include link();
    color: map-get($map: $colors, $key: primary);
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}
