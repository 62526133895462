@import "../config";
@import "../mixins/typography";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0.5rem;
  padding-right: 1rem;

  &--top-divider {
    border-top: solid;
    border-top-color: map-get($map: $colors, $key: background);
    border-top-width: 0.125rem;
  }

  &--bottom-divider {
    border-bottom: solid;
    border-bottom-color: map-get($map: $colors, $key: background);
    border-bottom-width: 0.125rem;
  }

  &--clickable {
    &:hover {
      border-radius: map-get($map: $spacing, $key: borderRadius);
      cursor: pointer;
      background-color: lighten(map-get($map: $colors, $key: primary), 35%);

      .title {
        color: darken(map-get($map: $colors, $key: primary), 20%);
      }

      .subtitle {
        color: darken(map-get($map: $colors, $key: primary), 20%);
      }
    }
  }

  &--nohover {
    &:hover {
      background-color: map-get($map: $colors, $key: foreground);
      border-radius: 0rem;

      .title {
        @include type($color: copyDark, $font: "copy", $size: base);
      }

      .subtitle {
        @include type($color: copy, $font: "copy", $size: small);
      }
    }
  }

  &--active {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    background-color: lighten(map-get($map: $colors, $key: primary), 35%);

    .title {
      color: darken(map-get($map: $colors, $key: primary), 20%);
    }

    .subtitle {
      color: darken(map-get($map: $colors, $key: primary), 20%);
    }
  }
}

.content {
  flex: 1;
  margin-left: 1rem;
}

.title {
  padding: 0.25rem 0rem;
  @include type($color: copyDark, $font: "copy", $size: base);
}

.subtitle {
  @include type($color: copy, $font: "copy", $size: small);
}

.icon {
  width: 2rem;
  height: 2rem;
}