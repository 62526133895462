@import "../config";

.stars-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.star {
  display: inline-block;
  cursor: pointer;

  &-disabled {
    cursor: default;
  }

  &-enable-pointer {
    cursor: pointer;
  }
}