@import '../config';
@import '../mixins/typography';
@import '../mixins/inputs';
@import '../mixins/box-shadow';

.badge {
  @include type($size: tiny, $font: copyBold, $color: foreground);
  position: absolute;
  top: 0;
  right: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  background-color: map-get($map: $colors, $key: primary);
  outline: 2px solid map-get($map: $colors, $key: foreground);
  line-height: 0;

  &-secondary {
    background-color: lighten(map-get($map: $colors, $key: primary), 35%);
    color: map-get($map: $colors, $key: primary);
  }

  &-tertiary {
    background-color: map-get($map: $colors, $key: foreground);
    color: map-get($map: $colors, $key: primary);
  }

  &-yellow {
    background-color: map-get($map: $colors, $key: yellow);
  }

  &-red {
    background-color: $error-500;
  }
}
